import type { Portfolio } from '../types'

export const portfolioMap: { [key: string]: Portfolio } = {
  'kurt.me': {
    title: 'Kurt.me',
    repo: 'https://github.com/kurt-liao/terminal.me',
    appLink: 'https://me.kurtstories.com',
    en: ['This is my personal website with terminal style.', 'The website is built with Nuxt3 & Tailwindcss & TypeScript.', 'It\'s an interactive website that you can type some commands to interact with me😁'],
    tw: ['這是我的個人網站，使用 Nuxt3 & Tailwindcss & TypeScript 所建構，採用的是 terminal 的風格', '另外，這是一個互動式的網站(就像 terminal)，在這邊你可以打一些指令與我互動😁'],
    background: 'https://user-images.githubusercontent.com/32745146/198423735-d7615739-6317-4871-ba41-ddf139f7c0d7.png',
  },
  'kurt.blog': {
    title: 'Kurt Blog',
    repo: 'https://github.com/kurt-liao/github-style-plus',
    appLink: 'https://blog.kurtstories.com/',
    en: ['This is my personal blog. Obviously, the site was built in Github style.', 'The website was made by Hugo with the github-style-plus theme(You can find it in my github).', 'I\'ll share some issues I faced, some projects I did and some technologies of programming here. Follow me🤩'],
    tw: ['這是我的部落格，可以很明顯的看出來這是 Github 風格！', '此網站是使用 Hugo 建置，而主題則是 github-style-plus(可以在我的github 找到此專案)', '我會在這邊分享我遇到的問題/解法、一些我做的專案，還有一些我學到的有關程式的知識等等，快來追蹤我的部落格吧🤩'],
    background: 'https://user-images.githubusercontent.com/32745146/201956371-f540f662-9d67-4e4b-9a3b-a2205c2d79da.png',
  },
  'movies': {
    title: 'Nuxt3 Movies',
    repo: 'https://github.com/kurt-liao/nuxt3-movies',
    appLink: 'https://movies.kurtstories.com/',
    en: ['This is the website about movies and TV shows. It\'s similar to Netflix and Disney+ without playing videos for you😂', 'It\'s the project for me to practice Nuxt3 and Tailwindcss.', 'Although it\'s not real like Netflix, you can easily know the newest, most famous movies and TV shows here.', 'By the way, the api is taken from TMDB(Appreciate)!'],
    tw: ['此網站是電影、影集的集合，就像 Netflix、Disney+ 一樣，不過並不提供觀看影片😂', '這是我用來練習 Nuxt3 和 Tailwindcss 的作品', '你可以在此網站瀏覽最新的、最近熱門的、某一種類別的電影/影集', '另外，此網站的資料來源是 TMDB(非常感謝)!'],
    background: 'https://user-images.githubusercontent.com/32745146/199554060-27b87788-baa9-46cd-9cb6-c34821a2c327.png',
  },
  'travel-taiwan': {
    title: 'Travel Taiwan',
    repo: 'https://github.com/kurt-liao/travel-taiwan',
    en: ['A website that calculate how much you familiar with Taiwan.', 'It was made by pure JavaScript and CSS.', 'Go and try to see how many points you can get!'],
    tw: ['一個紀錄你去過台灣各個縣市的網站，使用純 JavaScript、CSS 製作', '趕快去點點看你可以獲得多少分數吧！'],
    appLink: 'https://taiwan.kurtstories.com/',
    background: 'https://user-images.githubusercontent.com/32745146/202212412-cba23407-d7ee-48ac-88e2-7b100585c052.png',
  },
  'so-stats': {
    title: 'SO Stats',
    repo: 'https://github.com/kurt-liao/so-stats',
    en: ['This is a tool that shows your Stack Overflow stats. It\'s not only show the stats but also prettify your Github profile.', 'Enter your Stack Overflow user ID, and it will return a svg that display your Stack Overflow stats.', 'Go and make your Github profile page more beautiful😍'],
    tw: ['這是一個工具讓你在 Github 個人頁面展現 Stack Overflow 的數據', '只要輸入你的使用者 ID，即會回傳一張顯示你的 Stack Overflow 的各項數據的 svg', '趕快用這個工具美化你的 Github 個人頁吧😍'],
    appLink: '',
    background: 'https://user-images.githubusercontent.com/32745146/202213942-a17e3bfa-7bf4-4786-a566-a4cd3e013d44.png',
  },
  'medium-story': {
    title: 'Medium Story',
    repo: 'https://github.com/kurt-liao/medium-story',
    en: ['Tool like SO Stats, this tool is also for you to prettify your Github profile.', 'Enter your Medium user ID and it returns a svg that shows your recently posts.', 'This project uses RSS to retrieve your posts, it can get most 10 posts.', 'Go and give it a try👈'],
    tw: ['跟 SO Stats 專案很像，這也是一個讓你美化 Github 個人頁的工具', '只要你輸入你的 Medium 使用者 ID，即會回傳一張卡片 SVG，上頭會顯示你最近的一篇文章', '此專案使用 RSS 技術，最多可以取得最近 10 篇文章', '手刀前往試試👈'],
    appLink: '',
    background: 'https://user-images.githubusercontent.com/32745146/202214181-7156492e-f18f-4243-a9f8-5a174da3c0ca.png',
  },
  'js-toys': {
    title: 'JS Toys',
    repo: 'https://github.com/kurt-liao/js-toys',
    en: ['This website displays few small projects, and those projects are for me to practice JavaScript api, library.', 'Some of projects are games, like card game, patatap...', 'Hope you will like it😝'],
    tw: ['這是一個展示各式各樣不同的小專案的網站，主要是我拿來熟悉各種 JS API、JS Library 的專案', '裡面也有一些小遊戲，像是翻卡遊戲、按按鍵發出聲音等等的', '歡迎去玩玩😝'],
    appLink: 'https://kurt-liao.github.io/js-toys/',
    background: 'https://user-images.githubusercontent.com/32745146/202328644-178c0535-a6c9-4659-b2dd-0d87b4e46857.png',
  },
  'start-nuxt3': {
    title: 'Start Nuxt3',
    repo: 'https://github.com/kurt-liao/start-nuxt3',
    en: ['This is a command line tool that help you to create a Nuxt3 app easily and fast. Just try it🙌'],
    tw: ['這是一個 command line 工具，用來快速建構 Nuxt3 專案，下載來使用看看吧🙌'],
    appLink: 'https://www.npmjs.com/package/start-nuxt3',
    background: 'https://raw.githubusercontent.com/kurt-liao/start-nuxt3/main/assets/demo.png',
  },
}
